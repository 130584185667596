import React from "react";
import Navbar from "./home/NavBar";
import Footer from "./home/Footer";

const Privacy_Policies_Apps = ({ app }) => {
  const getAppName = () => {
    switch (app) {
      case 'DRIVERS':
        return 'Deliveryplus Conductores';
      case 'ADMIN':
        return 'Deliveryplus Admin';
      case 'CUSTOMERS':
        return 'Deliveryplus Customers';
      default:
        return 'Deliveryplus';
    }
  };

  return (
    <>
      <Navbar regresar={false} />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Política de privacidad de {getAppName()}</h1>
        <p className="mb-4">Última actualización: 13 de enero de 2025</p>
        <p className="mb-4">
          Esta Política de Privacidad describe nuestras políticas y
          procedimientos sobre la recopilación, uso y divulgación de su
          información cuando utiliza el Servicio y le informa sobre sus derechos
          de privacidad y cómo le protege la ley. Utilizamos sus datos
          personales para prestar y mejorar nuestros servicios. Al utilizar
          nuestros servicios, Usted acepta la recopilación y el uso de su
          información de acuerdo con esta Política de Privacidad. Esta Política
          de Privacidad ha sido creada con la ayuda del Generador de Políticas
          de Privacidad.
        </p>
        <h2 className="text-2xl font-semibold mb-2">
          Interpretación y definiciones
        </h2>
        <h3 className="text-xl font-semibold mb-2">Interpretación</h3>
        <p className="mb-4">
          Las palabras cuya inicial aparece en mayúscula tienen los significados
          que se definen a continuación. Las siguientes definiciones tendrán el
          mismo significado independientemente de que aparezcan en singular o en
          plural.
        </p>
        <h3 className="text-xl font-semibold mb-2">Definiciones</h3>
        <p className="mb-4">A efectos de la presente Política de Privacidad:</p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Cuenta</strong> significa una cuenta única creada para que
            Usted acceda a nuestro Servicio o a partes de nuestro Servicio.
          </li>
          <li>
            <strong>Afiliada</strong> significa una entidad que controla, es
            controlada por o está bajo control común con una parte, donde
            "control" significa la propiedad del 50% o más de las acciones,
            participaciones u otros valores con derecho a voto para la elección
            de directores u otra autoridad de gestión.
          </li>
          <li>
            <strong>Aplicación</strong> se refiere a {getAppName()},
            el programa de software proporcionado por la Empresa.
          </li>
          <li>
            <strong>La Compañía</strong> (referida como "la Compañía",
            "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se refiere a Delivery
            Plus, Solar 11, Tarqui, Guayaquil, Guayas, Código Postal 090112.
          </li>
          <li>
            <strong>El país</strong> se refiere a: Ecuador
          </li>
          <li>
            <strong>Dispositivo</strong> cualquier aparato que pueda acceder al
            Servicio, como un ordenador, un teléfono móvil o una tableta
            digital.
          </li>
          <li>
            <strong>Los datos personales</strong> son cualquier información
            relativa a una persona física identificada o identificable.
          </li>
          <li>
            <strong>Servicio</strong> se refiere a la Aplicación.
          </li>
          <li>
            <strong>Por Proveedor de Servicios</strong> se entiende cualquier
            persona física o jurídica que procese los datos en nombre de la
            Empresa. Se refiere a terceras empresas o personas físicas
            contratadas por la Empresa para facilitar el Servicio, prestar el
            Servicio en nombre de la Empresa, realizar servicios relacionados
            con el Servicio o ayudar a la Empresa a analizar cómo se utiliza el
            Servicio.
          </li>
          <li>
            <strong>Por Datos de Uso</strong> se entienden los datos recogidos
            automáticamente, ya sean generados por el uso del Servicio o
            procedentes de la propia infraestructura del Servicio (por ejemplo,
            la duración de la visita a una página).
          </li>
          <li>
            <strong>Usted</strong> es la persona física que accede o utiliza el
            Servicio, o la empresa u otra entidad jurídica en nombre de la cual
            dicha persona física accede o utiliza el Servicio, según
            corresponda.
          </li>
        </ul>
        <h2 className="text-2xl font-semibold mb-2">
          Recogida y uso de sus datos personales
        </h2>
        <h3 className="text-xl font-semibold mb-2">Tipos de datos recogidos</h3>
        <h4 className="text-lg font-semibold mb-2">Datos personales</h4>
        <p className="mb-4">
          Al utilizar nuestro Servicio, es posible que le pidamos que nos
          proporcione cierta información de identificación personal que pueda
          utilizarse para ponernos en contacto con usted o identificarle. La
          información de identificación personal puede incluir, pero no se
          limita a:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Dirección de correo electrónico</li>
          <li>Nombre y apellidos</li>
          <li>Número de teléfono</li>
          <li>Dirección, Estado, Provincia, Código postal, Ciudad</li>
        </ul>
        <h4 className="text-lg font-semibold mb-2">Datos de uso</h4>
        <p className="mb-4">
          Los Datos de Uso se recogen automáticamente al utilizar el Servicio.
        </p>
        <p className="mb-4">
          Los Datos de uso pueden incluir información como la dirección de
          protocolo de Internet (por ejemplo, la dirección IP) de su
          dispositivo, el tipo de navegador, la versión del navegador, las
          páginas de nuestro que visita, la hora y la fecha de su visita, el
          tiempo que pasa en esas páginas, identificadores únicos de
          dispositivos y otros datos de diagnóstico. Cuando accede al Servicio
          por o a través de un dispositivo móvil, podemos recopilar cierta
          información automáticamente, incluyendo, entre otros, el tipo de
          dispositivo móvil que utiliza, el identificador único de su
          dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema
          operativo móvil, el tipo de navegador de Internet móvil que utiliza,
          identificadores únicos de dispositivo y otros datos de diagnóstico.
          También podemos recopilar información que su navegador envía cada vez
          que visita nuestro Servicio o cuando accede al Servicio por o a través
          de un dispositivo móvil.
        </p>
        <h4 className="text-lg font-semibold mb-2">
          Información recopilada durante el uso de la aplicación
        </h4>
        <p className="mb-4">
          Durante el uso de Nuestra Aplicación, y con el fin de proporcionar
          funciones de Nuestra Aplicación, podemos recopilar, con Su permiso
          previo:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Información sobre su ubicación</li>
          <li>
            Imágenes y otra información de la cámara y la fototeca de tu
            dispositivo
          </li>
        </ul>
        <p className="mb-4">
          Utilizamos esta información para proporcionar funciones de Nuestro
          Servicio, para mejorar y personalizar Nuestro Servicio. La información
          puede cargarse en los servidores de la Empresa y/o en el servidor de
          un Proveedor de Servicios o puede simplemente almacenarse en Su
          dispositivo. Puede activar o desactivar el acceso a esta información
          en cualquier momento, a través de la configuración de su dispositivo.
        </p>
        <h3 className="text-xl font-semibold mb-2">
          Uso de sus datos personales
        </h3>
        <p className="mb-4">
          La Empresa podrá utilizar los Datos Personales para los siguientes
          fines:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Proporcionar y mantener nuestro Servicio</strong>, incluida
            la supervisión del uso de nuestro Servicio.
          </li>
          <li>
            <strong>Para gestionar Su Cuenta</strong>: para gestionar su
            registro como usuario del . Los Datos Personales que proporcione
            pueden acceder a diferentes funcionalidades del Servicio que están
            disponibles para Usted usuario registrado.
          </li>
          <li>
            <strong>Para la ejecución de un contrato</strong>: el desarrollo,
            cumplimiento y realización del contrato de compra de los productos,
            artículos o servicios que Usted haya adquirido o de cualquier otro
            contrato con Nosotros a través del Servicio.
          </li>
          <li>
            <strong>Para ponernos en contacto con Usted</strong>: Para ponerse
            en contacto con Usted por correo electrónico, llamadas telefónicas,
            SMS u otras formas equivalentes de comunicación electrónica, como
            las notificaciones push de una aplicación móvil sobre
            actualizaciones o comunicaciones informativas relacionadas con las
            funcionalidades, productos o servicios contratados, incluidas las
            actualizaciones de seguridad, cuando sea necesario o razonable para
            su implementación.
          </li>
          <li>
            <strong>
              Proporcionarle noticias, ofertas especiales e información general
            </strong>{" "}
            sobre otros productos, servicios y eventos que ofrezcamos y que sean
            similares a los que ya ha comprado o sobre los que ha preguntado, a
            menos que haya optado por no recibir dicha información.
          </li>
          <li>
            <strong>Gestionar sus solicitudes</strong>: Para atender y gestionar
            Sus solicitudes a Nosotros.
          </li>
          <li>
            <strong>Para transferencias empresariales</strong>: Podemos utilizar
            Su información para evaluar o llevar a cabo una fusión,
            desinversión, reestructuración, reorganización, disolución u otra
            venta o transferencia de algunos o todos Nuestros activos, ya sea
            como empresa en funcionamiento o como parte de un procedimiento de
            quiebra, liquidación o similar, en el que los Datos personales que
            tenemos sobre los usuarios de nuestro Servicio se encuentren entre
            los activos transferidos.
          </li>
          <li>
            <strong>Para otros</strong>: Podemos utilizar su información para
            otros fines, como el análisis de datos, la identificación de
            tendencias de uso, la determinación de la eficacia de nuestras
            campañas promocionales y para evaluar y mejorar nuestro Servicio,
            productos, servicios, marketing y su experiencia.
          </li>
        </ul>
        <p className="mb-4">
          Podemos compartir su información personal en las siguientes
          situaciones:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Con proveedores de servicios</strong>: Podemos compartir su
            información personal con Proveedores de Servicios para supervisar y
            analizar el uso de nuestro Servicio, para ponernos en contacto con
            Usted.
          </li>
          <li>
            <strong>Para transferencias empresariales</strong>: Podemos
            compartir o transferir Su información personal en relación con, o
            durante las negociaciones de cualquier fusión, venta de activos de
            la Empresa, financiación o adquisición de todo o parte de Nuestro
            negocio a otra empresa.
          </li>
          <li>
            <strong>Con afiliados</strong>: Podemos compartir su información con
            Nuestras filiales, en cuyo caso exigimos a dichas filiales que
            respeten esta Política de privacidad. Las filiales incluyen nuestra
            empresa matriz y cualesquiera otras filiales, empresas conjuntas u
            otras empresas que controlemos o que estén bajo control común con
            nosotros.
          </li>
          <li>
            <strong>Con socios comerciales</strong>: Podemos compartir su
            información con nuestros socios comerciales para ofrecerle
            determinados productos, servicios o promociones.
          </li>
          <li>
            <strong>Con otros usuarios</strong>: cuando Usted comparte
            información personal o interactúa de otro modo en las áreas públicas
            con otros usuarios, dicha información puede ser vista por todos los
            usuarios y puede ser distribuida públicamente al exterior.
          </li>
          <li>
            <strong>Con su consentimiento</strong>: Podemos divulgar su
            información personal para cualquier otro propósito con Su
            consentimiento.
          </li>
        </ul>
        <h3 className="text-xl font-semibold mb-2">
          Conservación de sus datos personales
        </h3>
        <p className="mb-4">
          La Empresa conservará sus Datos Personales únicamente durante el
          tiempo que sea necesario para los fines establecidos en la presente
          Política de Privacidad. Conservaremos y utilizaremos sus Datos
          Personales en la medida necesaria para cumplir con nuestras
          obligaciones legales (por ejemplo, si estamos obligados a conservar
          sus datos para cumplir con la legislación aplicable), resolver
          conflictos y hacer cumplir nuestros acuerdos legales y políticas.
        </p>
        <p className="mb-4">
          La Empresa también conservará los Datos de Uso con fines de análisis
          interno. Por lo general, los Datos de Uso se conservan durante un
          período de tiempo más corto, excepto cuando estos datos se utilizan
          para reforzar la seguridad o mejorar la funcionalidad de Nuestro
          Servicio, o cuando estamos legalmente obligados a conservar estos
          datos durante períodos de tiempo más largos.
        </p>
        <h3 className="text-xl font-semibold mb-2">
          Transferencia de sus datos personales
        </h3>
        <p className="mb-4">
          Su información, incluidos los Datos Personales, se procesa en las
          oficinas operativas de la Empresa y en cualquier otro lugar donde se
          encuentren las partes implicadas en el procesamiento. Esto significa
          que esta información puede ser transferida a -y mantenida en-
          ordenadores situados fuera de su estado, provincia, país u otra
          jurisdicción gubernamental donde las leyes de protección de datos
          puedan diferir de las de su jurisdicción. Su consentimiento a esta
          Política de Privacidad seguido de su envío de dicha información
          representa su acuerdo con dicha transferencia. La Empresa tomará todas
          las medidas razonablemente necesarias para garantizar que sus datos
          sean tratados de forma segura y de acuerdo con esta Política de
          Privacidad y no se transferirán sus Datos Personales.
        </p>
        <p className="mb-4">
          se llevará a cabo a una organización o un país a menos que existan
          controles adecuados que incluyan la seguridad de sus datos y otra
          información personal.
        </p>
        <h3 className="text-xl font-semibold mb-2">
          Cumplimiento con el RGPD (Usuarios en la Unión Europea)
        </h3>
        <p className="mb-4">
          Aunque esta aplicación está diseñada principalmente para su uso en
          Ecuador, es posible que sea accedida por usuarios en la Unión Europea
          (UE). Si usted es residente de la UE, procesaremos su información
          personal de acuerdo con el Reglamento General de Protección de Datos
          (RGPD). Esto incluye derechos como:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            Solicitar acceso, corrección o eliminación de sus datos personales.
          </li>
          <li>
            Retirar su consentimiento para el procesamiento de sus datos en
            cualquier momento.
          </li>
          <li>
            Presentar una queja ante la autoridad de protección de datos
            correspondiente en su país de residencia.
          </li>
        </ul>
        <p className="mb-4">
          Si desea ejercer estos derechos o tiene alguna pregunta sobre cómo
          manejamos sus datos, puede contactarnos en deliveryplusec@gmail.com.
        </p>
        <h3 className="text-xl font-semibold mb-2">
          Borrar sus datos personales
        </h3>
        <p className="mb-4">
          Tiene derecho a eliminar o solicitar que le ayudemos a eliminar los
          Datos Personales que hayamos recopilado sobre Usted. Nuestro Servicio
          puede ofrecerle la posibilidad de eliminar cierta información sobre
          usted dentro del Servicio. Puede actualizar, modificar o eliminar su
          información en cualquier momento accediendo a su cuenta, si la tiene,
          y visitando la sección de configuración de la cuenta que le permite
          gestionar su información personal. También puede ponerse en contacto
          con nosotros para solicitar acceso, la corrección o la eliminación de
          cualquier información personal que haya facilitado. Sin embargo, tenga
          en cuenta que es posible que necesitemos conservar cierta información
          cuando tengamos una obligación legal o una base jurídica para hacerlo.
        </p>
        <h3 className="text-xl font-semibold mb-2">
          Divulgación de sus datos personales
        </h3>
        <h4 className="text-lg font-semibold mb-2">Operaciones comerciales</h4>
        <p className="mb-4">
          Si la empresa participa en una fusión, adquisición o venta de activos,
          sus datos personales pueden ser transferidos. Le avisaremos antes de
          que sus Datos Personales sean transferidos y queden sujetos a una
          Política de Privacidad diferente.
        </p>
        <h4 className="text-lg font-semibold mb-2">Cumplimiento de la ley</h4>
        <p className="mb-4">
          En determinadas circunstancias, la Empresa puede verse obligada a
          revelar sus Datos Personales si así se lo exige la ley o en respuesta
          a solicitudes válidas de autoridades públicas (por ejemplo, un
          tribunal o una agencia gubernamental).
        </p>
        <h4 className="text-lg font-semibold mb-2">Otros requisitos legales</h4>
        <p className="mb-4">
          La Empresa podrá revelar sus Datos Personales en la creencia de buena
          fe de que dicha acción es necesaria para:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Cumplir una obligación legal</li>
          <li>Proteger y defender los derechos o la propiedad de la empresa</li>
          <li>
            Prevenir o investigar posibles irregularidades en relación con el
            Servicio
          </li>
          <li>
            Proteger la seguridad personal de los usuarios del Servicio o del
            público.
          </li>
          <li>Protección frente a la responsabilidad jurídica</li>
        </ul>
        <h3 className="text-xl font-semibold mb-2">
          Seguridad de sus datos personales
        </h3>
        <p className="mb-4">
          La seguridad de sus Datos Personales es importante para nosotros, pero
          recuerde que ningún método de transmisión por Internet o de
          almacenamiento electrónico es seguro al 100%. Aunque nos esforzamos
          por utilizar medios comercialmente aceptables para proteger sus Datos
          Personales, no podemos garantizar su seguridad absoluta.
        </p>
        <h3 className="text-xl font-semibold mb-2">Privacidad infantil</h3>
        <p className="mb-4">
          Nuestro Servicio no se dirige a menores de 13 años. No recopilamos
          conscientemente información personal identificable de menores de 13
          años. Si usted es padre o tutor y sabe que su hijo nos ha
          proporcionado datos personales, póngase en contacto con nosotros. Si
          tenemos conocimiento de que hemos recopilado Datos Personales de
          cualquier persona menor de 13 años sin la verificación del
          consentimiento paterno, tomaremos medidas para eliminar esa
          información de nuestros servidores. Si necesitamos basarnos en el
          consentimiento como base legal para procesar su información y su país
          requiere el consentimiento de uno de los padres, podemos requerir el
          consentimiento de sus padres antes de recopilar y utilizar esa
          información.
        </p>
        <h3 className="text-xl font-semibold mb-2">
          Enlaces a otros sitios web
        </h3>
        <p className="mb-4">
          Nuestro Servicio puede contener enlaces a otros sitios web no
          gestionados por nosotros. Si hace clic en un enlace de terceros, será
          dirigido al sitio de ese tercero. Le recomendamos encarecidamente que
          revise la Política de Privacidad de cada sitio que visite. No tenemos
          ningún control ni asumimos ninguna responsabilidad por el contenido,
          las políticas de privacidad o las prácticas de los sitios o servicios
          de terceros.
        </p>
        <h3 className="text-xl font-semibold mb-2">
          Cambios en esta política de privacidad
        </h3>
        <p className="mb-4">
          Es posible que actualicemos Nuestra Política de Privacidad de vez en
          cuando. Le notificaremos cualquier cambio publicando la nueva Política
          de Privacidad en esta página. Se lo comunicaremos por correo
          electrónico y/o mediante un aviso destacado en nuestro Servicio, antes
          de que el cambio entre en vigor, y actualizaremos la fecha de "Última
          actualización" en la parte superior de esta Política de privacidad.
        </p>
        <p className="mb-4">
          Le aconsejamos que revise periódicamente esta Política de Privacidad
          para comprobar si se han producido cambios. Las modificaciones de esta
          Política de Privacidad entrarán en vigor cuando se publiquen en esta
          página.
        </p>
        <h3 className="text-xl font-semibold mb-2">Contacte con nosotros</h3>
        <p className="mb-4">
          Si tiene alguna pregunta o consulta sobre estas Políticas de
          Privacidad, puede comunicarse con Nosotros utilizando los siguientes
          datos:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Nombre: Delivery Plus</li>
          <li>
            Dirección: Solar 11, Tarqui, Guayaquil, Guayas, Código Postal
            090112.
          </li>
          <li>RUC: 0930077201001</li>
          <li>RODRIGO ASPIAZU MARIA BELEN</li>
          <li>Por correo electrónico deliveryplusec@gmail.com</li>
        </ul>
        <p className="mb-4">
          Para más información sobre la actividad económica registrada de esta
          persona, puede consultar la página oficial del Servicio de Rentas
          Internas del Ecuador (SRI) en el siguiente{" "}
          <a
            href="https://srienlinea.sri.gob.ec/sri-en-linea/SriRucWeb/ConsultaRuc/Consultas/consultaRuc"
            className="text-blue-500 underline"
          >
            enlace
          </a>
          .
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Privacy_Policies_Apps;
