import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faUser, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../home/NavBar';
import Footer from '../home/Footer';

const FirstDeliveryForm = () => {
  const [formData, setFormData] = useState({
    product: '',
    name: '',
    contact: '',
    location: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappNumber = '593995167942'; // Reemplaza con el número de WhatsApp al que quieres enviar el mensaje
    const message = `*Realizar mi primer delivery*\n\n*Producto:* ${formData.product}\n*Nombre:* ${formData.name}\n*Contacto:* ${formData.contact}\n*Ubicación:* ${formData.location}`;

    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <>
    <Navbar regresar={true}/>
    <div className="flex justify-center items-center min-h-screen bg-[#412CC5] py-8 px-4 sm:px-6 lg:px-8">
      <form className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full mx-4" onSubmit={handleSubmit}>
        <h1 className="text-2xl sm:text-3xl font-bold text-[#412CC5] text-center mb-6">
          <FontAwesomeIcon icon={faBoxOpen} className="mr-2" />
          Formulario para solocitar delivery
        </h1>
        <div className="mb-5">
          <label className="block text-gray-700 font-semibold">Producto</label>
          <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
            <FontAwesomeIcon icon={faBoxOpen} className="text-[#412CC5] mr-2" />
            <input
              type="text"
              name="product"
              value={formData.product}
              onChange={handleChange}
              className="w-full outline-none"
              placeholder="¿Qué producto quieres pedir?"
              required
            />
          </div>
        </div>
        <div className="mb-5">
          <label className="block text-gray-700 font-semibold">Nombre de la persona</label>
          <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
            <FontAwesomeIcon icon={faUser} className="text-[#412CC5] mr-2" />
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full outline-none"
              placeholder="Nombre completo"
              required
            />
          </div>
        </div>
        <div className="mb-5">
          <label className="block text-gray-700 font-semibold">Contacto</label>
          <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
            <FontAwesomeIcon icon={faPhone} className="text-[#412CC5] mr-2" />
            <input
              type="text"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              className="w-full outline-none"
              placeholder="Número de contacto"
              required
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold">Ubicación</label>
          <div className="flex items-center mt-2 p-2 border border-gray-300 rounded-lg">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-[#412CC5] mr-2" />
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className="w-full outline-none"
              placeholder="Ubicación de entrega"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-green-500 text-white p-3 rounded-lg hover:bg-green-600 transition-colors font-semibold"
        >
          Enviar a WhatsApp
        </button>
      </form>
    </div>
    <Footer />
    </>
  );
};

export default FirstDeliveryForm;
