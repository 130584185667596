import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faMapLocation,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import logoSinFondo from "../../images/logosApp/logo_d_p.png";
import imgGuayaquil from "../../images/banderas/guayaquil.jpg";
import imgQuito from "../../images/banderas/quito.png";
import imgEcuador from "../../images/banderas/ecuador.png";

const Footer = () => {
  // const navigate = useNavigate();

  // const goToCustomerPolicy = () => {
  //   navigate("/politicas-de-privacidad-deliveryplus-customers");
  // };

  // const goToAdminPolicy = () => {
  //   navigate("/politicas-de-privacidad-deliveryplus-admin");
  // };

  // const goToDriverPolicy = () => {
  //   navigate("/politicas-de-privacidad-deliveryplus-conductores");
  // };

  return (
    <footer className="bg-gradient-to-tr from-[#412CC5] via-[#3c29b4] to-[#281792] bg-opacity-85 py-6 px-4">
      <div className="container mx-auto flex flex-col items-center">
        {/* Imagen del Logo */}
        <img
          src={logoSinFondo}
          style={{ height: "100px", width: "auto" }}
          alt="Logo"
          className="rounded-2xl mb-4"
        />

        {/* Información de Contacto */}
        <div className="text-center">
          <h2 className="text-lg mx-auto font-semibold mb-2 text-white">
            Contáctanos:
          </h2>
          <p className="flex items-center mb-1 text-white justify-center">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-lg " />
            <a
              href="mailto:deliveryplusec@gmail.com"
              className="hover:underline font-bold ml-1"
            >
              deliveryplusec@gmail.com
            </a>
          </p>
          <p className="flex items-center mb-1 text-white justify-center">
            <FontAwesomeIcon icon={faPhone} className="mr-2 text-lg " />
            <a
              href="tel:+593995167942"
              className="hover:underline font-bold ml-1"
            >
              +593 098 765 4321
            </a>
          </p>
          <p className="flex items-center text-white justify-center">
            <FontAwesomeIcon icon={faMapLocation} className="mr-2 text-lg " />
            Av. 1 de Mayo y Av. 12 de Octubre
          </p>
        </div>

        {/* Sección de Redes Sociales */}
        <div className="flex flex-col items-center justify-center py-6 space-y-2">
          <h2 className="text-lg font-semibold pb-2 text-white">
            Síguenos en:
          </h2>
          <div className="flex space-x-4 ">
            <a
              href="https://www.facebook.com/deliveryplusecu"
              className="flex items-center text-white hover:text-[#247df1] text-lg"
            >
              <FontAwesomeIcon icon={faFacebook} className="text-4xl" />
            </a>
            <a
              href="https://www.instagram.com/deliveryplusec/"
              className="flex items-center text-white hover:text-[#C13584] text-lg"
            >
              <FontAwesomeIcon icon={faInstagram} className="text-4xl" />
            </a>
            <a
              href="https://www.youtube.com/"
              className="flex items-center text-white hover:text-[#1DA1F2] text-lg"
            >
              <FontAwesomeIcon icon={faYoutube} className="text-4xl" />
            </a>
          </div>
        </div>

        {/* Sección de Imágenes */}
        <div className="text-center text-white mt-6">
          <span className="flex justify-center flex-wrap gap-4">
            <img
              src={imgGuayaquil}
              alt="Guayaquil"
              className="h-12 w-20 rounded-[10px] shadow-lg transition-transform duration-300 transform hover:scale-110"
            />
            <img
              src={imgEcuador}
              alt="Ecuador"
              className="h-12 w-20 rounded-[10px] border-white shadow-lg transition-transform duration-300 transform hover:scale-110"
            />
            <img
              src={imgQuito}
              alt="Quito"
              className="h-12 w-20 rounded-[10px] shadow-lg transition-transform duration-300 transform hover:scale-110"
            />
          </span>
        </div>

        {/* Copyright */}
        <div className="text-center text-white mt-8 text-sm">
          <div className="space-y-2 md:space-y-0 md:space-x-4 flex flex-col items-center">
            <p className="text-sm text-white mb-4">
              © 2024 Delivery Plus. Todos los derechos reservados.
            </p>

            <div className="flex items-center space-x-2">
              <p className="text-white font-semibold mb-2">
                Términos y Condiciones
              </p>
            </div>
            <div className="flex gap-2 items-center space-x-2 font-normal">
              <a
                href="/terminos-aliados"
                className="text-[#F15A24] hover:underline"
              >
                Repartidores
              </a>
              <a
                href="/terminos-clientes"
                className="text-[#F15A24] hover:underline"
              >
                Clientes
              </a>
            </div>

            <div className="flex items-center space-x-2">
              <p className="text-white font-semibold mb-2 mt-4">
                Políticas de Privacidad
              </p>
            </div>
            <div className="flex gap-2 items-center space-x-2 font-normal">
              <a
                href="/politicas-de-privacidad-deliveryplus-customers"
                className="text-[#F15A24] hover:underline"
              >
                Deliveryplus Customers
              </a>
              <a
                href="/politicas-de-privacidad-deliveryplus-admin"
                className="text-[#F15A24] hover:underline"
              >
                Deliveryplus Admin
              </a>
              <a
                href="/politicas-de-privacidad-deliveryplus-conductores"
                className="text-[#F15A24] hover:underline"
              >
                Deliveryplus Conductores
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
